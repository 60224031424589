import { format, parseJSON, min, max } from 'date-fns';
import { pt } from 'date-fns/locale';
import { tryCatch } from './utils';

const brDateMask = 'dd/MM/yyyy';
const brDatetimeMask = 'dd/MM/yyyy HH:mm:ss'

export const formatJSONDate = (jsonDate, mask) => tryCatch(() => format(parseJSON(jsonDate), mask, {locale: pt}));
export const brDate = (date) => tryCatch(() => format(date, brDateMask, {locale: pt}));
export const brDateTime = (date) => tryCatch(() => format(date, brDatetimeMask, {locale: pt}));

/**  Mínimo valor de um Objeto Date. */
export const dateMinValue = () => new Date(-8640000000000000);

/** Máximo valor de um Objeto Date. */
export const dateMaxValue = () => new Date(8640000000000000);

/**
 * Diz se o parâmetro é uma data válida.
 * O parâmetro tem que ser instance of Date.
 * @param {*} date data a ser avaliada (deve ser instance of Date para retornar true )
 */
export const isValidDate = (date) => date instanceof Date && !isNaN(date);

/**
 * Diz se o parâmetro equivale à uma data válida. 
 * Obs. O parâmetro não precisa ser instance of Date. 
 * Pode ser um número ou string que represente um objeto Date.
 * @param {*} date data a ser avaliada (ou número / string equivalente a uma data)
 */
export const isEquivalentToDate = (date) => (date != null && isValidDate(new Date(date)));

/**
 * Se o valor informado for equivalente à uma data válida, retorna a data do parâmetro date.
 * Senão, retorna o valor default informado.
 * @param {*} date data a ser avaliada (ou número / string equivalente a uma data)
 * @param {*} defaultValue valor retornado caso o valor informado não seja equivalente a uma data válida.
 */
export const dateFallback = (date, defaultValue) => isEquivalentToDate(date) ? new Date(date) : defaultValue;

/**
 * Se o parâmetro passado for equivalente à uma data válida, retorna a data do Parâmetro,
 * senão, retorna o Mínimo valor para um Date.
 * @param {*} date Data a ser avaliada (ou número / string equivalente a uma data)
 */
export const dateOrDateMinValue = (date) => dateFallback(date, dateMinValue());

/**
 * Se o parâmetro passado for equivalente à uma data válida, retorna a data do Parâmetro,
 * senão, retorna o Máximo valor para um Date.
 * @param {*} date Data a ser avaliada (ou número / string equivalente a uma data)
 */
export const dateOrDateMaxValue = (date) => dateFallback(date, dateMaxValue());

/**
 * Retorna a data mais antiga entre a lista de datas. Senão retorna null.
 * * Parâmetros na lista que não forem equivalentes a uma data são ignorados.
 * @param  {...any} dates Lista de datas (ou números / strings equivalentes a uma data)
 */
export const oldest = (...dates) => dateFallback(min(dates.map(d => dateFallback(d, null)).filter(d => d != null)), null);

/**
 * Retorna a data mais recente entre a lista de datas. Senão retorna null.
 * * Parâmetros na lista que não forem equivalentes a uma data são ignorados.
 * @param  {...any} dates Lista de datas (ou números / strings equivalentes a uma data)
 */
export const newest = (...dates) => dateFallback(max(dates.map(d => dateFallback(d, null)).filter(d => d != null)), null); //latest


export const timeInMs = (time) => {
  const splitTime = time?.split?.(':')?.map?.(t => Number(t));

  if (splitTime?.length !== 3 || splitTime.includes(NaN)) {
    throw new Error('Time must be in "HH:mm:ss" format');
  }

  const [hours, minutes, seconds] = splitTime;

  return (
    (hours * 60 * 60 * 1000) + 
    (minutes * 60 * 1000) +
    (seconds * 1000)
  );


}


export const currentTimestamp = () => new Date().getTime();

// const x = () => fns.toda
/* export const formatJSONDate = (jsonDate, mask) => {
  try {
    return format(parseJSON(jsonDate), mask)
  } catch (e) {
    return null;
  }
}; */