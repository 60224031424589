import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form'
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ConsultaDocumentoService from 'services/ConsultaDocumentoService';
import COCService from 'services/COCService';
import KskInput from 'ksk/styledComponents/components/KskInput/KskInput';
import KskButton from 'ksk/styledComponents/components/KskButton/KskButton';
import KskForm from 'ksk/styledComponents/components/KskForm/KskForm';
import KskToast from 'ksk/styledComponents/components/KskToast/KskToast';
import useWindowScroll from 'ksk/hooks/useWindowScroll.jsx';
import { formatos } from 'utils/maskUtils';
import { getFeature } from 'config/features.config';
import { buildUrlSearchParams } from 'utils/utils';
import { createValidationResolver, validateIdSiconfi } from 'utils/validation-utils';
import { S } from './BuscaRapidaIdSiconfi.styles.js';
import { identificadorSiconfiFormatter } from 'utils/formatters.js';
import KskTooltip from 'ksk/styledComponents/components/KskPopover/KskTooltip.jsx';
import KskGrid from 'ksk/styledComponents/components/KskGrid/KskGrid.js';


const formID = `busca-idsiconfi-form`;

function BuscaRapidaIdSiconfi(){
    
    const form = useForm({
        mode:'onTouched',
        // resolver:createValidationResolver({
        //     // idSiconfi: yup.string().idSiconfi()
        // }),
        defaultValues:{
            idSiconfi:""
        }
    })
    
    const [scrollY, scrollTop] = useWindowScroll();
    
    const handleOnFocuBuscaRapidaIdSiconfi = e => {
        if(e.ctrlKey && e.shiftKey && (e.key === 'F'||e.key === 'f')){
            document.getElementById(formID).elements[0].focus();
            scrollTop();
        }
    }
    
    useEffect(() => {
        window.addEventListener("keydown", handleOnFocuBuscaRapidaIdSiconfi);
    }, [])

    const navigate = useNavigate();

    const doSearchByService = async (identificadorSiconfi,service)=>{
        const searchStr = buildUrlSearchParams({identificadorSiconfi});
        const response = await service.paginatedSearch({searchStr:searchStr,page:0,linesPerPage:1})
        if(response.data.totalElements>0){
          return response;
        }
        else{
            throw new Error(`Não há nenhum registro para o Identificador Siconfi ${identificadorSiconfiFormatter(identificadorSiconfi) }`);
        }
    }

    const doSearch = async () => {
        try {
            const idSiconfi = form?.getValues("idSiconfi");

            if(idSiconfi==null||""===idSiconfi){

                throw new Error('Campo obrigatório.');

            }else{

                validateIdSiconfi(idSiconfi);
                const tipoIdSiconfi = idSiconfi.substring(4,6);
                switch (tipoIdSiconfi) {
                    // COC
                    case "00":
                        const response00 = await doSearchByService(idSiconfi,COCService);
                        const id00 = response00.data.content[0].id
                        const urlFeature00 = getFeature('COC').route.url
                        navigate(urlFeature00+"/edit/"+id00);                    
                        break;
                    // Documento
                    case "04":
                        const response04 = await doSearchByService(idSiconfi,ConsultaDocumentoService);
                        const id04 = response04.data.content[0].identificadorCompleto
                        const urlFeature04 = getFeature('CONSULTA_PUBLICA_DOCUMENTO').route.url
                        navigate(urlFeature04+"/"+id04);        
                        break;
                        
                    default:
                        throw new Error("O tipo do identificador siconfi informado não permite busca rápida.");
                        break;
                }

            }
                        
            form.reset()            
            document.activeElement.blur();

        } catch (error) {
            toast.error(<KskToast error mainMessage={"Identificador Siconfi"} details={error.message} />);
        }
    }
    
    const onValidationError = useCallback((e) => {
        toast.error(<KskToast error mainMessage={"Identificador siconfi"} details="Erro no preenchimento, corrija os erros e tente novamente" />);
        console.error(`Identificador siconfi. Erro no preenchimento, corrija os erros e tente novamente:`, e);
    }, []);
    
    // caso esse componente seja disponbilizado para perfil 'Publico', deve ser incorporado o uso do captcha
    // const { captchaRef, executeWithCaptcha } = useCaptcha();
    const search = useCallback(form.handleSubmit(doSearch, onValidationError),[])
    
    return (<>
            <KskForm onSubmit={ search } id={formID} submitOnShiftEnter>
                <S.BuscaRapidaIdSiconfi>
                    <KskTooltip title="Foco: Ctrl + Shift + F" arrow placement="left" disableFocusListener="true" disableTouchListener="true">  
                        <div>
                            <KskInput
                                className="phone-1 tablet-1 desktop-1"
                                id="input-idsiconfi"
                                form={ form }
                                name="idSiconfi"
                                label="Identificador Siconfi"                            
                                mask={formatos.identificadorsiconfi.mask}
                                unmask={formatos.identificadorsiconfi.unmask}
                                submitOnEnter={true}
                                size={'small'}
                            />                           
                        </div>                      
                    </KskTooltip>
                    <KskButton 
                        className="phone-1 tablet-1 desktop-1"
                        id="btn-pesquisar"
                        primary 
                        iconButton
                        icon={ faSearch }
                        type="submit" 
                        busy={form?.formState?.isSubmitting}
                    />
                </S.BuscaRapidaIdSiconfi>
            </KskForm>
    </>)
} 

export default BuscaRapidaIdSiconfi